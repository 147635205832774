<template>
  <div id="ProductIdea">
    <Header nav="2"></Header>

    <div class="loading" v-if="showloading">
      <van-loading type="spinner" color="#206C47" size="24px" vertical>
        加载中...
      </van-loading>
    </div>

    <div class="container" v-else>
      <div class="banner">
        <img :src="bannerImg" />
      </div>

      <div class="idea">
        <div class="en"><span class="En">C</span><span>ONCEPT</span></div>
        <div class="ch">{{ pageList[0].title }}</div>
        <div class="pageTitle pageTitle1">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[0].name }}</div>
        </div>
        <div class="pageText pageText1">{{ pageList[0].content }}</div>
        <img class="pageImg pageImg1" :src="pageList[0].image" />
      </div>

      <div class="pageContent2">
        <div class="pageTitle">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[1].title }}</div>
        </div>
        <div class="pageText pageText2">{{ pageList[1].content }}</div>
        <img class="pageImg" :src="pageList[1].image" />
      </div>

      <div class="pageContent3">
        <div class="pageTitle">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[2].title }}</div>
        </div>
        <div class="pageText pageText3">{{ pageList[2].content }}</div>
        <img class="pageImg" :src="pageList[2].image" />
      </div>

      <div class="pageContent4">
        <div class="pageTitle">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[3].title }}</div>
        </div>
        <div class="pageText pageText4">{{ pageList[3].content }}</div>
        <img class="pageImg" :src="pageList[3].image" />
      </div>

      <div class="pageContent5">
        <img class="bgc5" :src="pageList[4].image_logo" />
        <div class="pagemain5">
          <div class="pageTitle pageTitle5">
            <img class="co-icon" src="../assets/co-icon2.png" />
            <div>{{ pageList[4].title }}</div>
          </div>
          <div class="pageText pageText5">{{ pageList[4].content }}</div>
        </div>
      </div>

      <div class="pageContent6">
        <img class="pageImg pageImg6" :src="pageList[5].image" />
        <div class="pageTitle pageTitle6">
          <img class="co-icon" src="../assets/co-icon.png" />
          <div>{{ pageList[5].title }}</div>
        </div>
        <div class="pageText">{{ pageList[5].content }}</div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { Icon, Loading } from "vant";
export default {
  components: {
    Header,
    Footer,
    [Icon.name]: Icon,
    [Loading.name]: Loading,
  },
  data() {
    return {
      bannerImg: "",
      showloading: true,
      pageList: [],
    };
  },
  mounted() {
    // 轮播图
    this.$axios
      .post("https://api1.pyua.net/index/banner", { type: 8 })
      .then((res) => {
        // console.log(res.data.arr);
        if (res && res.code == 1) {
          this.bannerImg = res.data.arr[0].image;
          setTimeout(() => {
            this.showloading = false;
          }, 300);
        }
      });

    this.$axios
      .post("https://api1.pyua.net/index/content", { type: 8 })
      .then((res) => {
        // console.log(res.data.arr);
        if (res && res.code == 1) {
          this.pageList = res.data.arr;
        }
      });
  },
  methods: {},
};
</script>

<style lang="less" scoped>
#ProductIdea {
  box-sizing: border-box;
}
.banner {
  margin-top: 2.75rem;
  font-size: 0;
  img {
    width: 100%;
  }
}

.loading {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  margin-top: 60%;
}

.en {
  font-family: "DIN";
  margin-top: 2.9375rem;
  margin-bottom: 0.3125rem;
  color: #d5e1db;
  font-size: 18px;
  line-height: 1;
  .En {
    font-size: 28px;
    line-height: 1;
  }
}
.ch {
  line-height: 1;
  color: #206c47;
  font-weight: 700;
  font-size: 18px;
}

.pageTitle {
  color: #206c47;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageName {
  color: #206c47;
  font-size: 14px;
}
.pageText {
  color: #333;
  font-size: 14px;
  line-height: 1.5;
  text-align: justify;
  white-space: pre-wrap;
}
.pageImg {
  width: 100%;
}
.co-icon {
  width: 0.84375rem;
  height: 0.84375rem;
  margin-right: 0.5rem;
}

.idea {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  .pageTitle1 {
    margin: 1.875rem 0 1.5rem 0;
  }
  .pageText1 {
    text-align: center;
  }
  .pageImg1 {
    margin: 1.875rem 0 3.0625rem 0;
  }
}

.pageContent2 {
  background-color: #f4f8f6;
  padding: 3.0625rem 1rem;
  .pageText2{
    margin: 1.5rem 0 1.875rem 0;
  }
}

.pageContent3 {
  padding: 3.0625rem 1rem;
  .pageText3 {
    margin: 1.5rem 0 1.875rem 0;
  }
}

.pageContent4 {
  background-color: #f4f8f6;
  padding: 3.0625rem 1rem;
  .pageText4 {
    margin: 1.5rem 0 1.875rem 0;
  }
}

.pageContent5 {
  position: relative;
  .bgc5 {
    width: 100%;
    height: 29.8125rem;
  }
  .pagemain5 {
    position: absolute;
    top: 0;
    left: 0;
    padding: 3.0625rem 1rem;
    height: 100%;
    width: 100%;
    .pageTitle5 {
      color: #fff;
    }
    .pageText5 {
      color: #fff;
      margin-top: 1.5rem;
    }
  }
}

.pageContent6 {
  padding: 3.0625rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  .pageImg6 {
    width: 80%;
  }
  .pageTitle6 {
    margin: 1.875rem 0 1.5rem 0;
  }
}
</style>